import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/jenkins_home/workspace/outstation.web_main/app/ideaclipsen/components/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/jenkins_home/workspace/outstation.web_main/app/ideaclipsen/pdf/[pdfPage]/components/PaginationFooter.tsx");
;
import(/* webpackMode: "eager" */ "/var/jenkins_home/workspace/outstation.web_main/app/ideaclipsen/pdf/[pdfPage]/components/pdflist.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/var/jenkins_home/workspace/outstation.web_main/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/var/jenkins_home/workspace/outstation.web_main/node_modules/next/font/google/target.css?{\"path\":\"app/ideaclipsen/pdf/[pdfPage]/components/MdPage.tsx\",\"import\":\"Tiro_Tamil\",\"arguments\":[{\"weight\":\"400\",\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/var/jenkins_home/workspace/outstation.web_main/node_modules/next/font/google/target.css?{\"path\":\"app/ideaclipsen/pdf/[pdfPage]/components/MobilePage.tsx\",\"import\":\"Tiro_Tamil\",\"arguments\":[{\"weight\":\"400\",\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
