'use client';
import Pagination from '@/components/Pagination/Pagination';
import SmPagination from '@/components/Pagination/SmPagination';
import { useRouter } from 'next/navigation';


interface IProps {
  page: number;
  total: number
}
export default function PaginationFooter(props: IProps) {
  const router = useRouter();
  const prefix = '/pdf';
  return (
    <section className='pagination-part flex items-center flex-col'>
      <div className="pc-footer w-[1580px] px-[150px]">
        <Pagination curPage={props.page}
          onChange={(newPage) => router.push(`${prefix}/${newPage}`)}
          total={props.total}
          className=" text-black  pb-[60px] max-md:hidden !justify-start"
          arrowStyle='w-9 h-9'
          btnStyle='!w-9 !h-9'
          activeStyle='text-[#0032B3] underline'
        ></Pagination>
      </div>
      <SmPagination curPage={props.page}
        onChange={(newPage) => router.push(`${prefix}/${newPage}`)}
        total={props.total}
        className='font-medium text-black text-sm px-[58px] pt-[25px] pb-[70px] hidden max-md:flex'
        arrowStyle='w-8 h-8'
        textStyle='text-[#191300] text-[14px] leading-[19px]'
        inputStyle='bg-[#F6F6F5] rounded-[4px]'
        btnStyle='w-8 h-8 mx-7 flex items-center justify-center rounded-full cursor-pointer cursor-pointer'
        ignoreBorderStyle
        lng='en'
      />
    </section>
  );
}
